/* eslint-disable react/prop-types */
//import useState hook to create menu collapse state
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import * as Actions from "../../store/Actions/localTokenAction";

import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  Grid,
  Dialog,
  DialogTitle,
  Alert,
  DialogContent,
  DialogContentText,
  Tooltip,
} from "@mui/material";
import {
  PlaylistAdd,
  AirportShuttle,
  Settings,
  Logout,
  Assignment,
  StackedBarChart,
  Calculate,
  DriveFolderUpload,
  People,
  Vaccines,
  Analytics,
  LockReset,
  QueryStatsOutlined,
} from "@mui/icons-material";
import ChangePasswordDialog from "../../pages/components/ChangePasswordDialog";
import { useTheme } from "@mui/material/styles";

const SidebarComponent = (props) => {
  const [openErrorPopup, setOpenErrorPopup] = useState(false);
  const [openChangePasswordPopup, setChangePasswordPopup] = useState(false);

  const { logout } = useAuth0();
  const dispatch = useDispatch();
  const theme = useTheme();

  const SIDEBAR_SX = {
    background: theme.palette.primary.main,
    height: "100%",
    position: "fixed",
  };
  const LINK_SX = {
    textDecoration: "none",
    color: "white",
    textColor: "white",
  };

  const { user } = useSelector((state) => {
    return {
      user: state.auth.user,
    };
  });

  const is_not_local_installation = () => {
    //console.log(process.env.REACT_APP_LOCAL_INSTALLATION === "YES");
    return !(process.env.REACT_APP_LOCAL_INSTALLATION === "YES");
  };

  const sidebarMenus = () => {
    let listItems = [];
    //console.log("user is ", user);
    if (user) {
      let permissions = user.permission_list;

      if (permissions) {
        if (permissions.includes("NEW_TEST_CSV")) {
          listItems.push({
            listIcon: <PlaylistAdd style={{ color: "white" }} />,
            listText: "Register new tests",
            path: "/register_csv",
          });
        }

        if (permissions.includes("NEW_TEST_CSV_SF")) {
          listItems.push({
            listIcon: <PlaylistAdd style={{ color: "white" }} />,
            listText: "Register new tests",
            path: "/register_csv_sf",
          });
        }

        if (permissions.includes("NEW_TEST_CSV_ATC")) {
          listItems.push({
            listIcon: <PlaylistAdd style={{ color: "white" }} />,
            listText: "Register new tests",
            path: "/register_csv_atc",
          });
        }

        if (permissions.includes("NEW_TEST_FORM")) {
          listItems.push({
            listIcon: <PlaylistAdd style={{ color: "white" }} />,
            listText: "Order new test",
            path: "/register_fill",
          });
        }
        if (permissions.includes("NEW_TEST_FORM_PHYS")) {
          listItems.push({
            listIcon: <PlaylistAdd style={{ color: "white" }} />,
            listText: "Order new test",
            path: "/register_fill_physician",
          });
        }

        if (permissions.includes("TEST_LIST")) {
          listItems.push({
            listIcon: <Assignment style={{ color: "white" }} />,
            listText: "Test list",
            path: "/testlist",
          });
        }
        if (permissions.includes("VARIANTS_CS")) {
          listItems.push({
            listIcon: <Analytics style={{ color: "white" }} />,
            listText: "Test list",
            path: "/variantscs",
          });
        }
        if (permissions.includes("PATIENT_LIST")) {
          listItems.push({
            listIcon: <People style={{ color: "white" }} />,
            listText: "Patient list",
            path: "/patientList",
          });
        }
        if (permissions.includes("SF_PATIENT_LIST")) {
          listItems.push({
            listIcon: <People style={{ color: "white" }} />,
            listText: "Patient list",
            path: "/sfpatientlist",
          });
        }

        if (permissions.includes("GENETIC_IDAT")) {
          listItems.push({
            listIcon: <StackedBarChart style={{ color: "white" }} />,
            listText: "Upload idat",
            path: "/loadIdat",
          });
        }
        if (permissions.includes("GENETIC_BATCH_IDAT")) {
          listItems.push({
            listIcon: <DriveFolderUpload style={{ color: "white" }} />,
            listText: "Upload batch idat",
            path: "/loadBatchIdat",
          });
        }
        if (permissions.includes("GENETIC_CEL")) {
          listItems.push({
            listIcon: <StackedBarChart style={{ color: "white" }} />,
            listText: "Upload cel",
            path: "/loadCel",
          });
        }
        if (permissions.includes("GENETIC_CEL")) {
          listItems.push({
            listIcon: <DriveFolderUpload style={{ color: "white" }} />,
            listText: "Upload batch cel",
            path: "/loadBatchCel",
          });
        }

        if (permissions.includes("GENETIC_VCF")) {
          listItems.push({
            listIcon: <StackedBarChart style={{ color: "white" }} />,
            listText: "Upload VCF",
            path: "/loadVcf",
          });
        }

        if (permissions.includes("GENETIC_VCF")) {
          listItems.push({
            listIcon: <DriveFolderUpload style={{ color: "white" }} />,
            listText: "Upload batch VCF",
            path: "/loadBatchVcf",
          });
        }

        if (permissions.includes("GENETIC_FASTQ")) {
          listItems.push({
            listIcon: <StackedBarChart style={{ color: "white" }} />,
            listText: "Upload FASTQ",
            path: "/loadFastq",
          });
        }

        if (permissions.includes("TRACKING")) {
          listItems.push({
            listIcon: <AirportShuttle style={{ color: "white" }} />,
            listText: "Tracking",
            path: "/tracking",
          });
        }
        if (permissions.includes("PRS_CALCULATOR")) {
          listItems.push({
            listIcon: <Calculate style={{ color: "white" }} />,
            listText: "PRS+PCE calculator",
            path: "/prsCalculator",
          });
        }
        if (permissions.includes("READ_PROTEIN_DRUG")) {
          listItems.push({
            listIcon: <Vaccines style={{ color: "white" }} />,
            listText: "PRISM",
            path: "/PRISMList",
          });
        }
        if (permissions.includes("RESULT_STATISTICS")) {
          listItems.push({
            listIcon: <QueryStatsOutlined style={{ color: "white" }} />,
            listText: "Statistics",
            path: "/resultStatistics",
          });
        }
      }
      //if the user has no permissions
      if (listItems.length === 0) {
        setOpenErrorPopup(true);
        setTimeout(() => {
          //close the popup
          setOpenErrorPopup(false);
          //redirect to logout
          process.env.REACT_APP_LOCAL_INSTALLATION === "YES"
            ? local_logout()
            : logout({ returnTo: window.location.origin });
        }, 4000);
      }
    }
    return listItems;
  };

  const AccountSettings = () => {
    return (
      <ListItem sx={{ ...LINK_SX }} button>
        <ListItemIcon>
          <Settings sx={{ ...LINK_SX }} />
        </ListItemIcon>
        <Link to="settings" sx={{ ...LINK_SX }}>
          Account settings
        </Link>
      </ListItem>
    );
  };

  const ResetPasswordButton = () => {
    return (
      <ListItem sx={{ ...LINK_SX }} button>
        <ListItemIcon>
          <LockReset sx={{ ...LINK_SX }} />
        </ListItemIcon>
        <Link sx={{ ...LINK_SX }} onClick={() => setChangePasswordPopup(true)}>
          Change Password
        </Link>
      </ListItem>
    );
  };

  const local_logout = () => {
    dispatch(Actions.setLocalToken(null));
    // Get the current URL
    const currentURL = window.location.href;

    // Get the base URL without the path
    const baseURL = currentURL.split("/").slice(0, 3).join("/");

    // Redirect to the base URL
    window.location.href = baseURL;
  };

  return (
    <>
      <Box sx={{ ...SIDEBAR_SX }} component="div" height={"100vh"}>
        <Grid container directon={"column"} justifyContent={"space-between"}>
          <Grid item alignItems={"center"}>
            <List>
              {sidebarMenus().map((listItem, index) =>
                props.open ? (
                  <ListItem
                    sx={{ ...LINK_SX }}
                    button
                    key={index}
                    to={listItem.path}
                    component={Link}
                    style={{ color: "white" }}
                  >
                    <ListItemIcon sx={{ minWidth: 56 }}>
                      {listItem.listIcon}
                    </ListItemIcon>
                    {listItem.listText}
                  </ListItem>
                ) : (
                  <Tooltip
                    title={listItem.listText}
                    key={index}
                    placement="right"
                  >
                    <ListItem
                      sx={{ ...LINK_SX }}
                      button
                      key={index}
                      to={listItem.path}
                      component={Link}
                      style={{ color: "white" }}
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        {listItem.listIcon}
                      </ListItemIcon>
                    </ListItem>
                  </Tooltip>
                )
              )}
              {is_not_local_installation() ? (
                <div />
              ) : user.admin ? (
                <div />
              ) : (
                <ResetPasswordButton />
              )}

              {user ? (
                props.open ? (
                  <ListItem
                    sx={{ ...LINK_SX }}
                    button
                    component={Link}
                    style={{ color: "white" }}
                    onClick={() =>
                      process.env.REACT_APP_LOCAL_INSTALLATION === "YES"
                        ? local_logout()
                        : logout({ returnTo: window.location.origin })
                    }
                  >
                    <ListItemIcon sx={{ minWidth: 56 }}>
                      <Logout style={{ ...LINK_SX }} />
                    </ListItemIcon>
                    {props.open ? "Log out" : ""}
                  </ListItem>
                ) : (
                  <Tooltip
                    title={"Log out"}
                    placement="right"
                    sx={{ ...LINK_SX }}
                  >
                    <ListItem
                      sx={{ ...LINK_SX }}
                      button
                      component={Link}
                      style={{ color: "white" }}
                      onClick={() =>
                        process.env.REACT_APP_LOCAL_INSTALLATION === "YES"
                          ? local_logout()
                          : logout({ returnTo: window.location.origin })
                      }
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <Logout style={{ ...LINK_SX }} />
                      </ListItemIcon>
                    </ListItem>
                  </Tooltip>
                )
              ) : (
                <div />
              )}
            </List>
            {/*</Collapse>*/}
          </Grid>
        </Grid>
      </Box>
      <Dialog open={openErrorPopup}>
        <DialogTitle>
          <Alert severity="error">Access Error</Alert>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            User has NO PERMISSIONS to access this platform.
            <br />
            Redirect to Logout.
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {openChangePasswordPopup ? (
        <ChangePasswordDialog
          open={openChangePasswordPopup}
          onClose={() => setChangePasswordPopup(false)}
        />
      ) : (
        <div />
      )}
    </>
  );
};

export default SidebarComponent;
