import React from "react";

const RegisterCSV = React.lazy(() => import("./pages/Register_csv"));
const RegisterCSV_SF = React.lazy(() => import("./pages/Register_csv_sf"));
const RegisterCSV_ATC = React.lazy(() => import("./pages/Register_csv_atc"));

const RegisterFill = React.lazy(() => import("./pages/Register_fill"));
const RegisterFillPhysician = React.lazy(() =>
  import("./pages/Register_fill_physician")
);
const TestList = React.lazy(() => import("./pages/TestList"));
const PatientList = React.lazy(() => import("./pages/PatientList"));
const VariantsCS = React.lazy(() => import("./pages/VariantsTestList"));

const SFPatientList = React.lazy(() => import("./pages/SFPatientList"));
const PatientCard = React.lazy(() => import("./pages/Patientcard"));
const TrackingList = React.lazy(() => import("./pages/Tracking"));
const LoadIdat = React.lazy(() => import("./pages/LoadIdat"));
const LoadBatchIdat = React.lazy(() => import("./pages/LoadBatchIdat"));
const LoadCel = React.lazy(() => import("./pages/LoadCel"));
const LoadBatchCel = React.lazy(() => import("./pages/LoadBatchCel"));

const LoadVcf = React.lazy(() => import("./pages/LoadVcf"));
const LoadBatchVcf = React.lazy(() => import("./pages/LoadBatchVCF"));

const LoadFastq = React.lazy(() => import("./pages/LoadFastq"));

const PaymentResult = React.lazy(() => import("./pages/PaymentResult"));
const PRSCalculator = React.lazy(() => import("./pages/PRSCalculator"));
const PRISMList = React.lazy(() => import("./pages/PRISMList"));
const ResultStatistics = React.lazy(() => import("./pages/ResultStatistics"));

const routes = [
  //  { path: "/", exact: true, name: "PatientList" },
  { path: "/register_csv", name: "Register", element: RegisterCSV },
  { path: "/register_csv_sf", name: "Register", element: RegisterCSV_SF },
  { path: "/register_csv_atc", name: "Register", element: RegisterCSV_ATC },

  { path: "/register_fill", name: "Register", element: RegisterFill },
  {
    path: "/register_fill_physician",
    name: "Register",
    element: RegisterFillPhysician,
  },

  { path: "/testlist", name: "TestList", element: TestList },
  { path: "/patientlist", name: "PatientList", element: PatientList },
  { path: "/variantscs", name: "VariantsCS", element: VariantsCS },
  { path: "/sfpatientlist", name: "SFPatientlist", element: SFPatientList },
  { path: "/patientcard", name: "Patients", element: PatientCard },
  { path: "/tracking", name: "Tracking", element: TrackingList },
  { path: "/loadIdat", name: "LoadIdat", element: LoadIdat },
  { path: "/loadBatchIdat", name: "LoadBatchIdat", element: LoadBatchIdat },
  { path: "/loadCel", name: "LoadCel", element: LoadCel },
  { path: "/loadBatchCel", name: "LoadBatchCel", element: LoadBatchCel },

  { path: "/loadVcf", name: "LoadVcf", element: LoadVcf },
  { path: "/loadBatchVcf", name: "LoadVcf", element: LoadBatchVcf },

  { path: "/loadFastq", name: "LoadFastq", element: LoadFastq },
  { path: "/paymentResult", name: "PaymentResult", element: PaymentResult },
  { path: "/prsCalculator", name: "PRSCalculator", element: PRSCalculator },
  { path: "/PRISMList", name: "PRISMList", element: PRISMList },
  {
    path: "/resultStatistics",
    name: "resultStatistics",
    element: ResultStatistics,
  },
];

export default routes;
