import { useState } from "react";
import {
  REGISTER_NEW_TEST,
  REGISTER_NEW_TEST_CSV,
  SEARCH_REGISTERED_TEST,
  UPLOAD_PATIENT_CONSENT,
  GET_PATIENT_REPORT,
  GET_PATIENT_CONSENT,
  GET_TRACKING_INFO,
  SEARCH_PATIENT_INFO,
  GET_USER_INFO,
  ASSIGN_BARCODE_PATIENT,
  DEALLOCATE_BARCODE_PATIENT,
  DELETE_PATIENT_CONSENT,
  UPLOAD_GENETIC_IDAT,
  DELETE_GENETIC_IDAT,
  PATIENT_DETAILS,
  UPLOAD_GENETIC_VCF,
  DELETE_GENETIC_VCF,
  GET_PERSONAL_PRS_LIST,
  PAYMENT_NEW_TEST,
  CREATE_PAYMENT_INTENT,
  ALLELICA_RISK_CALC,
  UPDATE_PATIENT_INFO,
  PATIENT_LIST_VIEW,
  SEARCH_PRISM_LIST,
  GET_PRISM_PROTEIN_ANNOTATIONS,
  GET_PRISM_PROTEIN_FUNCTIONS,
  UPLOAD_GENETIC_FASTQ,
  DELETE_GENETIC_FASTQ,
  GET_PRISM_CSV,
  MODIFY_PRS_LIST_TEST,
  SEARCH_REGISTERED_TEST_VARIANTS,
  GET_VARIANT_LIST,
  GET_VARIANT_CSV,
  CHECK_VARIANT,
  CHANGE_USER_PASSWORD,
  DELETE_GENETIC_CEL,
  UPLOAD_GENETIC_CEL,
  RUN_LOCAL_IMAGE,
  RUN_LOCAL_DOCKER_IMAGE,
  GENERATE_LOCAL_REPORT,
  RUN_LOCAL_PATIENT_DOCKER_IMAGE,
  SEARCH_JOINT_REPORTS,
  DOWNLOAD_JOINT_REPORT,
  CHANGE_PATHOGENIC_VARIANT,
  GET_VARIANT_LIST_CNV,
  GET_VARIANT_LIST_SNV,
  GET_VARIANT_FILTERS,
  SAVE_VARIANT_FILTER,
  DELETE_VARIANT_FILTER,
  SAVE_USER_CONFIG,
  SAVE_VARIANT_SAMPLE_DEFAULT_FILTER,
  SAVE_X_FRAGILE,
  GET_TEST_COMMENTS,
  ADD_TEST_COMMENT,
  GET_VARIANT_COMMENTS,
  ADD_VARIANT_COMMENT,
  CHANGE_PATHOGENIC_VARIANT_FOREVER,
  SAVE_ADDITIONA_GENE_INFO,
  GET_RESULT_STATISTICS,
} from "./constants";

import useAxiosConfig from "./useAxiosConfig";

const useApi = () => {
  const { axiosInstance, isLoading, renewTimeout } = useAxiosConfig();

  const get_user_info = async (funct) => {
    const { url, method } = GET_USER_INFO;
    const result = await axiosInstance[method](url, {
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return {};
  };

  const change_user_password = async (datas, funct) => {
    const { url, method } = CHANGE_USER_PASSWORD;
    const result = await axiosInstance[method](url, datas, {
      onUploadProgress: funct,
    });

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const save_user_configuration = async (datas, funct) => {
    const { url, method } = SAVE_USER_CONFIG;
    const result = await axiosInstance[method](url, datas, {
      onUploadProgress: funct,
    });

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const register_new_test = async (datas, funct) => {
    const { url, method } = REGISTER_NEW_TEST;
    const result = await axiosInstance[method](url, datas, {
      onUploadProgress: funct,
    });

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const modify_prs_list_test = async (datas) => {
    const { url, method } = MODIFY_PRS_LIST_TEST;
    const result = await axiosInstance[method](url, datas);

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const update_patient_info = async (datas, funct) => {
    const { url, method } = UPDATE_PATIENT_INFO;
    const result = await axiosInstance[method](url, datas, {
      onUploadProgress: funct,
    });

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const register_new_test_csv = async (datas, funct) => {
    const { url, method } = REGISTER_NEW_TEST_CSV;
    const result = await axiosInstance[method](url, datas, {
      onUploadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return {};
  };

  const search_registered_test = async (params, funct) => {
    //orderId
    const { url, method } = SEARCH_REGISTERED_TEST;
    const result = await axiosInstance[method](url, {
      params: params,
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return [];
  };

  const search_registered_test_variants = async (params, funct) => {
    //orderId
    const { url, method } = SEARCH_REGISTERED_TEST_VARIANTS;
    const result = await axiosInstance[method](url, {
      params: params,
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return [];
  };

  const search_joint_reports = async (params, funct) => {
    //orderId
    const { url, method } = SEARCH_JOINT_REPORTS;
    const result = await axiosInstance[method](url, {
      params: params,
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return [];
  };

  const download_joint_report = async (params, funct) => {
    //orderId
    const { url, method } = DOWNLOAD_JOINT_REPORT;
    const result = await axiosInstance[method](url, {
      params: params,
      responseType: "blob",
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "report_" +
          params.malePatientId +
          "_" +
          params.femalePatientId +
          "_" +
          params.language +
          "." +
          params.type
      ); //or any other extension      document.body.appendChild(link);
      link.click();
    }

    return { state: "error" };
  };

  const patient_list_view = async (params, funct) => {
    //orderId
    const { url, method } = PATIENT_LIST_VIEW;
    const result = await axiosInstance[method](url, {
      params: params,
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return [];
  };

  const upload_patient_consent = async (datas, funct) => {
    const { url, method } = UPLOAD_PATIENT_CONSENT;
    const result = await axiosInstance[method](url, datas, {
      onUploadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      console.log(items);
      return items;
    }

    return {};
  };

  const get_patient_report = async (params, funct, patientName) => {
    //orderId
    const { url, method } = GET_PATIENT_REPORT;
    const result = await axiosInstance[method](url, {
      params: params,
      responseType: "blob",
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      //check if we can add also the patientName to the filename
      let patientStringToAdd = "";
      if (patientName && patientName.length > 1) {
        //transform spaces into underscores
        patientStringToAdd = patientName.replace(/ /g, "_");
        //then add an underscore in front of the name
        patientStringToAdd = "_" + patientStringToAdd;
      }

      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "report_" +
          params.orderId +
          "_" +
          params.language +
          patientStringToAdd +
          "." +
          params.type
      ); //or any other extension      document.body.appendChild(link);
      link.click();
    }

    return { state: "error" };
  };

  const get_patient_consent = async (params, funct) => {
    console.log("get patient Consent ", funct);
    const { url, method } = GET_PATIENT_CONSENT;
    const result = await axiosInstance[method](url, {
      params: params,
      responseType: "blob",
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "consent_" + params.orderId + ".pdf"); //or any other extension
      document.body.appendChild(link);
      link.click();
    }

    return { state: "error" };
  };

  const get_tracking_info = async (params, funct) => {
    //orderId
    const { url, method } = GET_TRACKING_INFO;
    const result = await axiosInstance[method](url, {
      params: params,
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;

      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return {};
  };

  const search_patient_info = async (params, funct) => {
    //patientId
    const { url, method } = SEARCH_PATIENT_INFO;
    const result = await axiosInstance[method](url, {
      params: params,
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;

      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return [];
  };

  const patient_details = async (params, funct) => {
    //patientId
    const { url, method } = PATIENT_DETAILS;
    const result = await axiosInstance[method](url, {
      params: params,
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return {};
  };

  const assign_barcode_patient = async (datas, funct) => {
    const { url, method } = ASSIGN_BARCODE_PATIENT;
    const result = await axiosInstance[method](url, datas, {
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      console.log(items);
      return items;
    }

    return result;
  };

  const deallocate_barcode_patient = async (datas, funct) => {
    const { url, method } = DEALLOCATE_BARCODE_PATIENT;
    const result = await axiosInstance[method](url, datas, {
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      console.log(items);
      return items;
    }

    return result;
  };

  const delete_patient_consent = async (datas, funct) => {
    const { url, method } = DELETE_PATIENT_CONSENT;
    const result = await axiosInstance[method](url, datas, {
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      console.log(items);
      return items;
    }

    return result;
  };

  const upload_genetic_idat = async (datas, funct) => {
    const { url, method } = UPLOAD_GENETIC_IDAT;
    const result = await axiosInstance[method](url, datas, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      console.log(items);
      return items;
    }

    return [];
  };

  const delete_genetic_idat = async (datas, funct) => {
    const { url, method } = DELETE_GENETIC_IDAT;
    const result = await axiosInstance[method](url, datas, {
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      console.log(items);
      return items;
    }

    return [];
  };

  const upload_genetic_vcf = async (datas, funct) => {
    const { url, method } = UPLOAD_GENETIC_VCF;
    const result = await axiosInstance[method](url, datas, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      console.log(items);
      return items;
    }

    return [];
  };

  const delete_genetic_vcf = async (datas, funct) => {
    const { url, method } = DELETE_GENETIC_VCF;
    const result = await axiosInstance[method](url, datas, {
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      console.log(items);
      return items;
    }

    return [];
  };

  const upload_genetic_cel = async (datas, funct) => {
    const { url, method } = UPLOAD_GENETIC_CEL;
    const result = await axiosInstance[method](url, datas, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      return items;
    }

    return [];
  };

  const delete_genetic_cel = async (datas, funct) => {
    const { url, method } = DELETE_GENETIC_CEL;
    const result = await axiosInstance[method](url, datas, {
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      console.log(items);
      return items;
    }

    return [];
  };

  const upload_genetic_fastq = async (datas, funct) => {
    const { url, method } = UPLOAD_GENETIC_FASTQ;
    const result = await axiosInstance[method](url, datas, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      console.log(items);
      return items;
    }

    return [];
  };

  const delete_genetic_fastq = async (datas, funct) => {
    const { url, method } = DELETE_GENETIC_FASTQ;
    const result = await axiosInstance[method](url, datas, {
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      console.log(items);
      return items;
    }

    return [];
  };

  const create_payment_intent = async (params, funct) => {
    //patientId
    const { url, method } = CREATE_PAYMENT_INTENT;
    const result = await axiosInstance[method](url, params);
    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return {};
  };

  //risk calculator
  const allelica_risk_calculator = async (params, funct) => {
    //patientId
    const { url, method } = ALLELICA_RISK_CALC;
    const result = await axiosInstance[method](url, { params: params });
    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return {};
  };

  const get_variant_list = async (params, funct) => {
    //orderId
    const { url, method } = GET_VARIANT_LIST;
    const result = await axiosInstance[method](url, params, {
      headers: {
        "Content-Type": "application/json",
      },
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      let items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.rows.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      items = { ...items, rows: [...keyed_items] };

      return items;
    }

    return {};
  };

  const get_variant_list_cnv = async (params, funct) => {
    //orderId
    const { url, method } = GET_VARIANT_LIST_CNV;
    const result = await axiosInstance[method](url, params, {
      headers: {
        "Content-Type": "application/json",
      },
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      let items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.rows.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      items = { ...items, rows: [...keyed_items] };

      return items;
    }

    return {};
  };

  const get_variant_list_snv = async (params, funct) => {
    //orderId
    const { url, method } = GET_VARIANT_LIST_SNV;
    const result = await axiosInstance[method](url, params, {
      headers: {
        "Content-Type": "application/json",
      },
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      let items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.rows.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      items = { ...items, rows: [...keyed_items] };

      return items;
    }

    return {};
  };

  const get_variant_csv = async (datas, funct) => {
    //orderId
    const { url, method } = GET_VARIANT_CSV;
    const result = await axiosInstance[method](url, datas, {
      headers: {
        "Content-Type": "application/json",
      },
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "variants.csv");
      document.body.appendChild(link);
      link.click();
    }

    return { state: "error" };
  };

  const check_variant = async (datas, funct) => {
    const { url, method } = CHECK_VARIANT;
    const result = await axiosInstance[method](url, datas, {
      headers: {
        "Content-Type": "application/json",
      },
      onUploadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      console.log(items);
      return items;
    }

    return {};
  };

  const change_pathogenic_variant = async (datas, funct) => {
    const { url, method } = CHANGE_PATHOGENIC_VARIANT;
    const result = await axiosInstance[method](url, datas, {
      headers: {
        "Content-Type": "application/json",
      },
      onUploadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      console.log(items);
      return items;
    }

    return {};
  };
  const change_pathogenic_variant_forever = async (datas, funct) => {
    const { url, method } = CHANGE_PATHOGENIC_VARIANT_FOREVER;
    const result = await axiosInstance[method](url, datas, {
      headers: {
        "Content-Type": "application/json",
      },
      onUploadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      console.log(items);
      return items;
    }

    return {};
  };

  const get_variant_filters = async (params, funct) => {
    //orderId
    const { url, method } = GET_VARIANT_FILTERS;
    const result = await axiosInstance[method](url, {
      params: params,
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return [];
  };

  const save_variant_filter = async (datas, funct) => {
    const { url, method } = SAVE_VARIANT_FILTER;
    const result = await axiosInstance[method](url, datas, {
      headers: {
        "Content-Type": "application/json",
      },
      onUploadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      console.log(items);
      return items;
    }

    return {};
  };
  const delete_variant_filter = async (datas, funct) => {
    const { url, method } = DELETE_VARIANT_FILTER;
    const result = await axiosInstance[method](url, datas, {
      headers: {
        "Content-Type": "application/json",
      },
      onUploadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      console.log(items);
      return items;
    }

    return {};
  };

  const save_variant_sample_default_filter = async (datas, funct) => {
    const { url, method } = SAVE_VARIANT_SAMPLE_DEFAULT_FILTER;
    const result = await axiosInstance[method](url, datas, {
      headers: {
        "Content-Type": "application/json",
      },
      onUploadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      console.log(items);
      return items;
    }

    return {};
  };

  const save_x_fragile = async (datas, funct) => {
    const { url, method } = SAVE_X_FRAGILE;
    const result = await axiosInstance[method](url, datas, {
      headers: {
        "Content-Type": "application/json",
      },
      onUploadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      console.log(items);
      return items;
    }

    return {};
  };

  const save_additional_gene_info = async (datas, funct) => {
    const { url, method } = SAVE_ADDITIONA_GENE_INFO;
    const result = await axiosInstance[method](url, datas, {
      headers: {
        "Content-Type": "application/json",
      },
      onUploadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      console.log(items);
      return items;
    }

    return {};
  };

  const get_variant_comments = async (params, funct) => {
    //orderId
    const { url, method } = GET_VARIANT_COMMENTS;
    const result = await axiosInstance[method](url, {
      params: params,
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return [];
  };

  const add_variant_comment = async (datas, funct) => {
    const { url, method } = ADD_VARIANT_COMMENT;
    const result = await axiosInstance[method](url, datas, {
      headers: {
        "Content-Type": "application/json",
      },
      onUploadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      console.log(items);
      return items;
    }

    return {};
  };

  const get_test_comments = async (params, funct) => {
    //orderId
    const { url, method } = GET_TEST_COMMENTS;
    const result = await axiosInstance[method](url, {
      params: params,
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return [];
  };

  const add_test_comment = async (datas, funct) => {
    const { url, method } = ADD_TEST_COMMENT;
    const result = await axiosInstance[method](url, datas, {
      headers: {
        "Content-Type": "application/json",
      },
      onUploadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      console.log(items);
      return items;
    }

    return {};
  };

  /*
   *
   *      THIS IS THE PRISM PROJECT
   *
   *
   */
  const search_prism_list = async (datas, funct) => {
    //orderId
    const { url, method } = SEARCH_PRISM_LIST;
    const result = await axiosInstance[method](url, datas, {
      headers: {
        "Content-Type": "application/json",
      },
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      let items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      // items.rows is an array and should be added a key for each element
      const keyed_items = items.rows.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      items = { ...items, rows: [...keyed_items] };

      return items;
    }

    return {};
  };

  const get_prism_protein_annotations = async (params, funct) => {
    console.log(params);
    //orderId
    const { url, method } = GET_PRISM_PROTEIN_ANNOTATIONS;
    const result = await axiosInstance[method](url, {
      params: params,
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return [];
  };

  const get_prism_protein_functions = async (params, funct) => {
    //orderId
    const { url, method } = GET_PRISM_PROTEIN_FUNCTIONS;
    const result = await axiosInstance[method](url, {
      params: params,
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return [];
  };

  const get_prism_csv = async (datas, funct) => {
    //orderId
    const { url, method } = GET_PRISM_CSV;
    const result = await axiosInstance[method](url, datas, {
      headers: {
        "Content-Type": "application/json",
      },
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "prism.csv");
      document.body.appendChild(link);
      link.click();
    }

    return { state: "error" };
  };

  const run_local_test = async (datas, funct) => {
    const { url, method } = RUN_LOCAL_DOCKER_IMAGE;
    const result = await axiosInstance[method](url, datas, {
      onUploadProgress: funct,
    });

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const run_local_patient_test = async (datas, funct) => {
    const { url, method } = RUN_LOCAL_PATIENT_DOCKER_IMAGE;
    const result = await axiosInstance[method](url, datas, {
      onUploadProgress: funct,
    });

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const generate_local_report = async (datas, funct) => {
    const { url, method } = GENERATE_LOCAL_REPORT;
    const result = await axiosInstance[method](url, datas, {
      onUploadProgress: funct,
    });

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const get_result_statistics = async (params, funct) => {
    //orderId
    const { url, method } = GET_RESULT_STATISTICS;
    const result = await axiosInstance[method](url, {
      params: params,
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return [];
  };

  return {
    register_new_test,
    modify_prs_list_test,
    update_patient_info,
    register_new_test_csv,
    search_registered_test,
    search_registered_test_variants,
    patient_list_view,
    upload_patient_consent,
    get_patient_consent,
    search_patient_info,
    patient_details,
    get_patient_report,
    get_tracking_info,
    get_user_info,
    save_user_configuration,
    change_user_password,
    assign_barcode_patient,
    deallocate_barcode_patient,
    delete_patient_consent,
    upload_genetic_idat,
    delete_genetic_idat,
    upload_genetic_vcf,
    delete_genetic_vcf,
    upload_genetic_cel,
    delete_genetic_cel,
    upload_genetic_fastq,
    delete_genetic_fastq,
    create_payment_intent,
    allelica_risk_calculator,
    search_prism_list,
    get_prism_protein_annotations,
    get_prism_protein_functions,
    get_prism_csv,
    get_variant_list,
    get_variant_list_cnv,
    get_variant_list_snv,
    get_variant_csv,
    save_x_fragile,
    save_additional_gene_info,
    get_variant_comments,
    add_variant_comment,
    change_pathogenic_variant,
    change_pathogenic_variant_forever,
    check_variant,
    get_variant_filters,
    save_variant_filter,
    delete_variant_filter,
    save_variant_sample_default_filter,
    run_local_test,
    run_local_patient_test,
    generate_local_report,
    search_joint_reports,
    download_joint_report,
    get_test_comments,
    add_test_comment,
    get_result_statistics,
    isLoading,
    renewTimeout,
  };
};

export default useApi;
